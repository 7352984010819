import './App.css';
import investrsAbi from './investrsAbi.json';
import {ethers, BigNumber} from 'ethers';
import {useEffect, useState} from 'react';

const investrsAddress = "0x4db61258AED4e592Db3e62a07951f6cbC009627A";
let isActive = false;

//page variables
let mSupply, cSupply;

function App() {
  //Connecting
  const [accounts, setAccounts] = useState([]);

async function connectAccounts(){
  if(window.ethereum){
    const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
    });
    setAccounts(accounts);
    getContractData();
    mintStartCheck();
  }
}
let provider, signer, contract
if(window.ethereum){
provider = ((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum, "any"): ethers.getDefaultProvider());
signer = provider.getSigner();
contract = new ethers.Contract(investrsAddress, investrsAbi.abi, signer);
}

function LinkDiv(){
  return <div className='LinksContainer'>
    <a href='https://twitter.com/nftinvestrs' target="_blank" id="twitter">
      <img src={require('./media/twitterIcon.webp')} width="80px" />
    </a>
    <a href='https://opensea.io/collection/nftinvestrs' target="_blank" id="opensea">
      <img src={require('./media/osIcon.webp')} width="80px" />
    </a>
  </div> 
}
function MainContainer(){
  return <div className="container">
    <img id='logo' loading='lazy' src={require('./media/clownsHeader.gif')} />
    <div className='minter'>
    <a href='https://raritysniper.com/nft-drops-calendar' target="_blank" id="raritySniper">
      <img id='investr' loading='lazy' src={require('./media/clown.webp')} />
    </a>
      <ul className='whiteboard'>
        <li id='freeMint'>
          FREE MINT
        </li>
        <li id='supply'>
          5210 investrs
        </li>
        <li className='smallPrint'>
          Management reservs <strong>210</strong> investrs
        </li>
        <li className='redStrikeThrough'>
          Roadmap
        </li>
        <li className='redStrikeThrough'>
          Utility
        </li>
        <li id='cc0'>
          CC0 &amp; <strong>0 Royalties</strong>
        </li>
        <li className='smallPrint'>
          4 per wallet
        </li>
        <li id='smartContract'>
          <a href={'https://etherscan.io/address/'+investrsAddress} target="_blank">
            very smart contract
          </a>
        </li>
        <h2 id="clownsLeft"></h2>
      </ul>
    </div>
    <ButtonContainer/>
    <p className='disclaimerText'>*HONK* MINT ONLY WITH METAMASK ON DESKTOP *HONK*</p>
  </div>
}
function ButtonContainer(){
  return <div>
    {!accounts.length ? (
      <div className='buttonPos'>
        <button onClick ={() => {connectAccounts()}} id="connectButton">Connect Wallet</button>
      </div>):(
      <div>
          <div className='mintAdder' id='mintHeader'>
            <button onClick={handleMintAmountN}>-</button>
            <button onClick={handleMint} id="mintButton">MINT {mintAmount}</button>
            <button onClick={handleMintAmountP}>+</button>
            <p id="errorCall"></p>
          </div>
          <div className='buttonPos' id='soldOutHeader'>
            <button id="soldOutButton">SOLD OUT</button>
          </div>
          <div className='buttonPos' id='noMintHeader'>
            <button id="noMintButton">NOT STARTED</button>
          </div>
      </div>
      )}
    </div>
}

//getContractData
async function getContractData(){

  mSupply = await checkMsupply();
  cSupply = await checkCsupply();
  if(isActive){
    document.getElementById("noMintHeader").style.display = "none";
    document.getElementById("clownsLeft").innerHTML = cSupply+ "/" + mSupply;
    if(cSupply>=mSupply){
      document.getElementById("mintHeader").style.display = "none";
      document.getElementById("soldOutHeader").style.display = "block";
    }
    else{
      document.getElementById("soldOutHeader").style.display = "none";
      document.getElementById("mintHeader").style.display = "block";
    }
  }
  else{
    document.getElementById("noMintHeader").style.display = "block";
  }
}

//MINTING
let [mintAmount, setMintAmount] = useState(1);
function handleMintAmountP(){
  if(mintAmount>=4){
    mintAmount = 4;
  }
  else{
    mintAmount= mintAmount + 1;
  }
  document.getElementById("mintButton").innerHTML = "MINT "+ mintAmount;
}
function handleMintAmountN(){
  if(mintAmount<=1){
    mintAmount = 1;
  }
  else{
    mintAmount= mintAmount - 1;
  }
  document.getElementById("mintButton").innerHTML = "MINT "+ mintAmount;
}

async function handleMint(){
  if (window.ethereum) {
      try {
        const response = await contract.mint(BigNumber.from(mintAmount));
        console.log("repsonse: ", response);
        document.getElementById("errorCall").innerHTML = "";
      } catch(err) {
        console.log(err.reason);
        if(err.reason==="Error: VM Exception while processing transaction: reverted with reason string '*HONKHONK* Don't get GREEDY, I won't give you more than that HAHAHA!'"){
          document.getElementById("errorCall").innerHTML = "HAHAHA Don't be Greedy! You can't mint that much! *HONKHONK*";
        }
        if(err.reason==="Error: VM Exception while processing transaction: reverted with reason string 'Actually we don't have that many CLOWNS in here *HONKHONKHONK*!'"){
          document.getElementById("errorCall").innerHTML = "We don't have that many HAHAHA! Try to mint less *HONKHONK*";
        }
      }
  }
}

// check current supply 
async function checkCsupply(){
  if (window.ethereum) {
      try {
        const currentSupply = await contract.totalSupply();
        return currentSupply.toNumber();
      } catch(err) {
        console.log("error: ", err);
      }
  }
}
// check max supply 
async function checkMsupply(){
  if (window.ethereum) {
      try {
        const maxSupply = await contract.CLOWN_STAFF();
        return maxSupply.toNumber();
      } catch(err) {
        console.log("error: ", err);
      }
  }
}
//check if sale has started
async function mintStartCheck(){
  if (window.ethereum) {
      try {
        const hasStarted = await contract.started();
        isActive = hasStarted;
      } catch(err) {
        console.log("error: ", err);
      }
  }
}

  return (
    <div className="App">
      < LinkDiv/>
      < MainContainer/>
      
    </div>
  );
}

export default App;
